<template>
  <main class="Financial">
    <TheSubHeader   
      ref="theSubHeader"
      icon="money"
      title="Financeiro"
      subtitle="Aprovação Financeiro"
      page-title-tag="Financial Approve"
      type="routerTabs"
      organizer-gap="1rem"
    >
      <template #afterButtons>
        <BaseButton
          v-if="permissions && permissions.editVehicles && permissions.editVehicles.isVisible"
          icon="truck-delivered"
          color="primary-base"
          label="Tipo de Veículo"
          class="car-type-button"
          :track="trackSubheaderButtonString('Financial Approve', 'Financial Edit Vehicle')"
          @click="goToEditVehicle()"
        />
        <BaseButton
          icon="status"
          color="primary-base"
          label="Custo de operação"
          class="operation-button"
          :track="trackSubheaderButtonString('Financial Approve', 'Operation Cost')"
          @click="openModalOperation()"
        />
      </template>

      <template #filter>
        <section
          class="Financial__subheaderTab Financial__subheaderTab--financialApprove"
        >  
          <!-- Drivers filter -->
          <BaseTreeSelect
            v-model="filters.driver"
            name="Motorista"
            label="Motorista / Beneficiário"
            placeholder="Pesquisar"
            multiple
            suggestions="searchDrivers"
            @input="updateTags(), fetchFinancialApprove()"
          />
          <!-- Type value filter -->
          <BaseTreeSelect
            v-model="filters.valueType"
            name="type"
            label="Tipo"
            :searchable="false"
            placeholder="Selecione"
            :enable-paste="false"
            :select-options="valueType"
            @input="fetchFinancialApprove()"
          />
          <!-- Stores filter -->
          <BaseTreeSelect
            v-model="filters.store"
            name="Cliente / Loja"
            label="Cliente / Loja"
            placeholder="Selecione"
            multiple
            :enable-paste="false"
            :select-options="null"
            :fetch-options="'stores/fetchStoresAsync'"
            @input="updateTags(), fetchFinancialApprove()"
          />

          <!-- Operation filter -->
          <BaseTreeSelect
            v-model="filters.operationType"
            name="Operation"
            label="Operação"
            placeholder="Selecione"
            :enable-paste="false"
            :select-options="operationType"
            @input="fetchFinancialApprove()"
          />

          <!-- Status filter -->
          <BaseTreeSelect
            v-model="filters.status"
            name="Status"
            label="Status"
            placeholder="Selecione"
            :enable-paste="false"
            :select-options="statusList"
            @input="fetchFinancialApprove()"
          />

          <!-- Status filter -->
          <BaseTreeSelect
            v-model="filters.view"
            name="Visualização"
            label="Visualização"
            placeholder="Selecione"
            :enable-paste="false"
            :select-options="viewsList"
            @input="fetchFinancialApprove()"
          />
        </section>
      </template>

      <template v-if="allTags.length" #tag>
        <FiltersTag
          :tags="allTags"
          @removeTag="removeTag"
          @clear="clearAllTags"
        />
      </template>
    </TheSubHeader>

    <!-- History uploaded sheets component -->
    <section class="Financial__content">
      <div class="financial-approve-wrapper">
        <div class="Financial__tab Financial__tab--financialApprove"> 
          <DataTable
            :header="financialApprove.data.headers"
            :table="financialApprove.data.content"
            :max-size="false"
            @input="selectItem"
            @select="setStatusValue"
            @comment="handlerChatMessenger"
          />

          <div class="Financial__operations">
            <div v-for="item in operations" :key="item.color" class="Financial__operations--item">
              <span class="circle" :class="`circle-${item.color}`" />
              {{ item.label }}
            </div>
          </div>
          
          <div class="financial-approve-wrapper__buttons">
            <!-- action buttons -->
            <BaseButton
              v-for="button in buttons"
              :key="button.id"
              class="action-button"
              :label="button.label"
              color="primary-base"
              filled
              text-color="white"
              :disabled="!financialApprove.data.content.length"
              @click="handlerModal(button)"
            />

            <!-- confirm status button -->
            <BaseButton
              label="Confirmar Status"
              color="primary-base"
              class="action-button"
              filled
              text-color="white"
              :disabled="!hasValue"
              @click="approve"
            />
          </div>

          <Pagination @page="fetchFinancialApprove" @paginate="fetchFinancialApprove">
            <div v-if="financialApprove.data.append" class="financial-approve-wrapper__cost">
              {{ financialApprove.data.append.append_total_base_value }}
              <br>
              {{ financialApprove.data.append.append_total_cost_value }}
              <br>
              <strong>{{ financialApprove.data.append.end_day }}</strong>
            </div>
          </Pagination>

          <ChatMessenger 
            ref="ChatMessenger" 
            :messages="analystsComments.data.content" 
            @fetchMessages="fetchMessages"
            @sendMessage="sendMessage"
          />

          <FinancialApproveDialog ref="FinancialApproveDialog" />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
//Api
import { financial } from '@/api'

import { mapActions, mapState } from 'vuex'
//Components
import { TheSubHeader } from '@/components/organisms'
import { BaseButton, BaseTreeSelect } from '@/components/atoms'
import { DataTable, ChatMessenger, Pagination, FiltersTag } from '@/components/molecules'
import FinancialApproveDialog from '@/views/financial/FinancialApprove/FinancialApproveDialog'

//Mixins
import FinancialMixin from '@/mixins/financial'

export default {
  name: 'FinancialApprove',
  components: {
    TheSubHeader,
    BaseButton,
    FiltersTag,
    BaseTreeSelect,
    DataTable, 
    ChatMessenger, 
    Pagination,
    FinancialApproveDialog
  },
  mixins: [FinancialMixin],
  data() {
    return {
      value: [],

      //Filters
      filters: {
        driver: [],
        store: [],
        status: null,
        valueType: null,
        operationType: null,
        view: null
      },

      operations: [
        { label: 'Operação online', color: 'green' },
        { label: 'Operação offline', color: 'grey' }
      ],

      //view filter options
      viewsList: [
        {
          id: 0,
          name: 'Todos'
        },
        {
          id: 1,
          name: 'Pedidos com Ajustes'
        },
        {
          id: 2,
          name: 'Pedidos sem Ajustes'
        }
      ],

      //buttons list
      buttons: [
        {
          id: 0,
          label: 'Reprovar',
          action: 'DisapproveAll',
        },
        {
          id: 1,
          label: 'Aprovar',
          action: 'ApproveAll',
        },
      ],

      // values options
      valueType: [
        {
          id: 0,
          name: 'Desconto',
          value: '<'
        },
        {
          id: 1,
          name: 'Acréscimo',
          value: '>='
        }
      ],

      // operation option
      operationType: [
        {
          id: '0',
          name: 'Offline'
        },
        {
          id: 1,
          name: 'Online'
        }
      ],

      //Tags
      allTags: []
    }
  },

  computed: {
    ...mapState({
      paginate: state => state.pagination.paginate,
      page: state => state.pagination.page,
      financialApprove: state => state.financial.financialApprove,
      analystsComments: state => state.financial.analystsComments,
      permissions: state => state.auth.permissions
    }),

    hasValue() {
      return !!this.value.length
    }
  },

  mounted() {
    this.initStatusList()

    // precisa adicionar o endpoint que vai ser feito pelo backend
    this.fetchFinancialApprove()
  },

  methods: {
    ...mapActions({
      clearPagination: 'pagination/clearPagination',
      fetchFinancialApproveItems: 'financial/fetchFinancialApprove',
      fetchAnalystsComments: 'financial/fetchAnalystsComments',
      setLoading: 'loading/setLoading',
      setPagination: 'pagination/setPagination',
      clearChatMessages: 'financial/clearChatMessages',
    }),

    handlerModal(action) {
      this.$refs.FinancialApproveDialog.handlerOpen(action)
    },

    removeTag(name, id){
      this.allTags = this.allTags.filter((tag) => tag.id !== id)
      this.filters.store = this.filters.store.filter((tag) => tag.name !== name)
      this.filters.driver = this.filters.driver.filter((tag) => tag.name !== name)
      this.fetchFinancialApprove()
    },

    clearAllTags(){
      this.allTags = []
      this.filters.store = []
      this.filters.driver = []
      this.filters.status = null
      this.filters.valueType = null
      this.filters.operationType = null
      this.filters.view = null
      this.fetchFinancialApprove()
    },

    updateTags(){
      this.allTags = [ ...this.filters.store, ...this.filters.driver ]
    },

    selectItem(data) {
      this.value = data
    },

    setStatusValue(data) {
      if(!data.inputs.find(el => el.optionSelected)) {
        this.$nextTick(() => {
          this.value = this.value.filter((v) => v.id !== data.id)
        })
      }

      this.value = this.value.filter((v) => v.id !== data.id)
      this.value.push(data)
    },

    handlerChatMessenger(data) {
      this.clearChatMessages().then(() => this.$refs.ChatMessenger.open(data.id))
    },

    async approve() {
      this.setLoading(true)

      const items = []

      for (let i in this.value) {
        const a = {
          id: this.value?.[i]?.id,
          status: this.value?.[i]?.inputs?.[0]?.optionSelected?.id
        }
        items.push(a)
      }

      const body = {
        costs: items
      }

      await financial.financialChangeStatus(body, (r) => {
        this.fetchFinancialApprove()
        this.$nextTick(() => {
          this.$refs.FinancialApproveDialog.setMessage(r.message)
        })
      }, (e) => {
        this.$refs.FinancialApproveDialog.setMessage(e.message)
      })
      
      this.setLoading(false)
    },

    async fetchFinancialApprove() {
      let payload = {
        obj: {
          beneficiary_id: this.filters.driver,
          store_id: this.filters.store,
          'additional_cost[signal]': this.filters.valueType?.value,
          'additional_cost[value]': this.filters.valueType?.value ? '0.0001' : null,
          type: this.filters.operationType,
          'status_id[]': this.filters.status?.id,
          view: this.filters?.view
        },
        page: this.page,
        paginate: this.paginate
      }

      await this.fetchFinancialApproveItems(payload, (r) => r, (e) => e, () => this.setLoading(false))
      this.setPagination(this.financialApprove)
    },

    async fetchMessages(id) {
      await this.fetchAnalystsComments(id).then(() => {
        this.$refs.ChatMessenger.scrollMessages()
      })
    },

    async sendMessage(id, message) {
      this.setLoading(true)
      const body = {
        operation_cost_id: id,
        message
      }
      await financial.submitManagerMessage(body, async () => {
        await this.fetchMessages(id)
        this.$refs.ChatMessenger.resetInput()
      }, (e) => e)
    },
  }
}
</script>

<style lang="scss" scoped>
.Financial {
  &__subheaderTab {
    display: grid;
    align-items: end;
    grid-gap: 1rem;
      @media #{$mobile-view} {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    
   &--financialApprove {
      display: flex;

      /deep/.vue-treeselect__control {
        margin-top: 8px;
      }
    }
  }

  &__modalHeader {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
  }

  &__content {
    display: flex;
    margin: 0 auto;
    max-width: 1180px;
    width: 100%;
    max-width: 1180px;
    flex-direction: column;
    padding: 0 0 3rem 0;
    @media (max-width: $viewport-lg) {
      padding: 0 1rem 3rem 1rem;
    }
  }

  &__operations {
    display: flex;
    margin-top: 1rem;
    gap: 20px;
    &--item {
      font-size: $font-size-3xsmall;
      gap: 5px;
      display: flex;
    }
  }

  .circle {
    height: 20px;
    width: 20px;
    border-radius: 100%;
    &-grey {
      background-color: $color-neutral-soft;
    }
    &-green {
      background-color: $color-success-soft;
    }
  }

  .financial-approve-wrapper {
    width: 100%;
  
    /deep/ .vue-treeselect .vue-treeselect__control {
      background: transparent;
  
      .vue-treeselect__placeholder {
        color: $color-neutral-stronger;
      }
    }
  
    &__buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 2rem;
  
      @media #{$mobile-view} {
        display: block;
        width: 100%;
      }
  
      .action-button {
        width: 150px;
        margin: 5px;
        height: 30px;
  
        @media #{$mobile-view} {
          width: 90%;
          margin: 10px auto;
        }
  
        &:last-child {
          margin-left: 80px;
          @media #{$mobile-view} {
            margin: 10px auto;
          }
        }
      }
    }
  
    &__cost {
      text-align: center;
      font-size: $font-size-mini;
      color: $color-neutral-stronger;
    }
  }
}
</style>