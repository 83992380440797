<template>
  <Modal v-if="show" class="FinancialApproveDialog" @close="handlerCancel">
    <template #header>
      <div class="FinancialApproveDialog__Header">
        Confirmação de custos
      </div>
    </template>
    <template #body>
      <div v-if="message" class="FinancialApproveDialog__Body">
        <div class="FinancialApproveDialog__Text">
          <p>
            {{ message }}
          </p>
        </div>
      </div>

      <div v-else class="FinancialApproveDialog__Body">
        <div class="FinancialApproveDialog__Text">
          <p>
            Você deseja <strong>{{ action }}</strong> todos os custos baseados na sua busca ou somente os custos mostrados em tela?
          </p>
        </div>
        
        <div class="FinancialApproveDialog__Actions">
          <BaseButton
            class="FinancialApproveDialog__Actions--buttons"
            :label="`${action} todos`"
            color="dark"
            filled
            text-color="white"
            @click="handlerRequestCosts('allCosts', action)"
          />
          <BaseButton
            class="FinancialApproveDialog__Actions--buttons"
            :label="`${action} custos em tela`"
            color="dark"
            filled
            text-color="white"
            @click="handlerRequestCosts('onScreen', action)"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { Modal } from '@/components/molecules'
import { BaseButton } from '@/components/atoms'
import { financial } from '@/api'

export default {
  name: 'FinancialApproveDialog',
  components: {
    Modal,
    BaseButton
  },
  
  data() {
    return {
      show: false,
      action: '',
      trigger: undefined,
      statusCode: null,
      message: ''
    }
  },

  computed: {
    ...mapState({
      financialApprove: state => state.financial.financialApprove,
    }),
  },

  methods: {
    ...mapActions({
      setLoading: 'loading/setLoading',
    }),

    //Handlers
    handlerOpen(button) {
      this[`handler${button.action}`](button)
      this.show = true
    },

    handlerCancel() {
      this.show = false
      this.message = ''
    },

    handlerSendAll(button) {
      this.action = button.label
      this.trigger = 'sendAll'
    },
    
    handlerDisapproveAll(button) {
      this.action = button.label
      this.trigger = 'disapproveAll'
    },

    handlerApproveAll(button) {
      this.action = button.label
      this.trigger = 'approveAll'
    },

    setStatusCode(operation) {
      let types = {
        'Reenviar': 2,
        'Reprovar': 3,
        'Aprovar': 5
      }
      this.statusCode = types[operation] || null
    },

    async handlerRequestCosts(type, operation) {
      this.setStatusCode(operation)
      this.setLoading(true)
      
      if (type === 'allCosts') {
        let type = {
          5: 'approve',
          3: 'reprove'
        }
        await financial.financialBatchChangeStatus(type[this.statusCode] || null, (res) => {
          this.setMessage(res.message)
        }, (e) => {
          this.setMessage(e.message)
        }, () => this.setLoading(false))

      } else {
        let body = {
          costs: this.financialApprove.data.content.map(el => {
            return {
              id: el.id, 
              status: this.statusCode
            }
          })
        }
        await financial.financialChangeStatus(body, (res) => {
          this.setMessage(res.message)
        }, (e) => {
          this.setMessage(e.message)
        }, () => this.setLoading(false))
      }
    },

    setMessage(message) {
      this.show = true
      this.message = message
    }
  },
}
</script>
<style lang="scss" scoped>
.FinancialApproveDialog {
  /deep/ .Modal__container {
    width: 35vw;
    height: auto;
  }

  &__Header {
    text-align: center;
    padding-top: 6px;
  }

  &__Text {
    text-align: center;
    padding: 1rem;
  }

  &__Actions {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;

    &--buttons {
      width: 230px;
      margin: 5px;
    }
  }
}
</style>